import { dew as _UtilityDew } from "./Utility";
import { dew as _XMLElementDew } from "./XMLElement";
import { dew as _XMLCDataDew } from "./XMLCData";
import { dew as _XMLCommentDew } from "./XMLComment";
import { dew as _XMLRawDew } from "./XMLRaw";
import { dew as _XMLTextDew } from "./XMLText";
import { dew as _XMLProcessingInstructionDew } from "./XMLProcessingInstruction";
import { dew as _XMLDeclarationDew } from "./XMLDeclaration";
import { dew as _XMLDocTypeDew } from "./XMLDocType";
import { dew as _XMLDTDAttListDew } from "./XMLDTDAttList";
import { dew as _XMLDTDEntityDew } from "./XMLDTDEntity";
import { dew as _XMLDTDElementDew } from "./XMLDTDElement";
import { dew as _XMLDTDNotationDew } from "./XMLDTDNotation";
import { dew as _XMLAttributeDew } from "./XMLAttribute";
import { dew as _XMLStringifierDew } from "./XMLStringifier";
import { dew as _XMLStringWriterDew } from "./XMLStringWriter";
var exports = {},
    _dewExec = false;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  // Generated by CoffeeScript 1.12.7
  (function () {
    var XMLAttribute,
        XMLCData,
        XMLComment,
        XMLDTDAttList,
        XMLDTDElement,
        XMLDTDEntity,
        XMLDTDNotation,
        XMLDeclaration,
        XMLDocType,
        XMLDocumentCB,
        XMLElement,
        XMLProcessingInstruction,
        XMLRaw,
        XMLStringWriter,
        XMLStringifier,
        XMLText,
        getValue,
        isFunction,
        isObject,
        isPlainObject,
        ref,
        hasProp = {}.hasOwnProperty;
    ref = _UtilityDew(), isObject = ref.isObject, isFunction = ref.isFunction, isPlainObject = ref.isPlainObject, getValue = ref.getValue;
    XMLElement = _XMLElementDew();
    XMLCData = _XMLCDataDew();
    XMLComment = _XMLCommentDew();
    XMLRaw = _XMLRawDew();
    XMLText = _XMLTextDew();
    XMLProcessingInstruction = _XMLProcessingInstructionDew();
    XMLDeclaration = _XMLDeclarationDew();
    XMLDocType = _XMLDocTypeDew();
    XMLDTDAttList = _XMLDTDAttListDew();
    XMLDTDEntity = _XMLDTDEntityDew();
    XMLDTDElement = _XMLDTDElementDew();
    XMLDTDNotation = _XMLDTDNotationDew();
    XMLAttribute = _XMLAttributeDew();
    XMLStringifier = _XMLStringifierDew();
    XMLStringWriter = _XMLStringWriterDew();

    exports = XMLDocumentCB = function () {
      function XMLDocumentCB(options, onData, onEnd) {
        var writerOptions;
        (this || _global).name = "?xml";
        options || (options = {});

        if (!options.writer) {
          options.writer = new XMLStringWriter(options);
        } else if (isPlainObject(options.writer)) {
          writerOptions = options.writer;
          options.writer = new XMLStringWriter(writerOptions);
        }

        (this || _global).options = options;
        (this || _global).writer = options.writer;
        (this || _global).stringify = new XMLStringifier(options);

        (this || _global).onDataCallback = onData || function () {};

        (this || _global).onEndCallback = onEnd || function () {};

        (this || _global).currentNode = null;
        (this || _global).currentLevel = -1;
        (this || _global).openTags = {};
        (this || _global).documentStarted = false;
        (this || _global).documentCompleted = false;
        (this || _global).root = null;
      }

      XMLDocumentCB.prototype.node = function (name, attributes, text) {
        var ref1, ref2;

        if (name == null) {
          throw new Error("Missing node name.");
        }

        if ((this || _global).root && (this || _global).currentLevel === -1) {
          throw new Error("Document can only have one root node. " + this.debugInfo(name));
        }

        this.openCurrent();
        name = getValue(name);

        if (attributes === null && text == null) {
          ref1 = [{}, null], attributes = ref1[0], text = ref1[1];
        }

        if (attributes == null) {
          attributes = {};
        }

        attributes = getValue(attributes);

        if (!isObject(attributes)) {
          ref2 = [attributes, text], text = ref2[0], attributes = ref2[1];
        }

        (this || _global).currentNode = new XMLElement(this || _global, name, attributes);
        (this || _global).currentNode.children = false;
        (this || _global).currentLevel++;
        (this || _global).openTags[(this || _global).currentLevel] = (this || _global).currentNode;

        if (text != null) {
          this.text(text);
        }

        return this || _global;
      };

      XMLDocumentCB.prototype.element = function (name, attributes, text) {
        if ((this || _global).currentNode && (this || _global).currentNode instanceof XMLDocType) {
          return (this || _global).dtdElement.apply(this || _global, arguments);
        } else {
          return this.node(name, attributes, text);
        }
      };

      XMLDocumentCB.prototype.attribute = function (name, value) {
        var attName, attValue;

        if (!(this || _global).currentNode || (this || _global).currentNode.children) {
          throw new Error("att() can only be used immediately after an ele() call in callback mode. " + this.debugInfo(name));
        }

        if (name != null) {
          name = getValue(name);
        }

        if (isObject(name)) {
          for (attName in name) {
            if (!hasProp.call(name, attName)) continue;
            attValue = name[attName];
            this.attribute(attName, attValue);
          }
        } else {
          if (isFunction(value)) {
            value = value.apply();
          }

          if (!(this || _global).options.skipNullAttributes || value != null) {
            (this || _global).currentNode.attributes[name] = new XMLAttribute(this || _global, name, value);
          }
        }

        return this || _global;
      };

      XMLDocumentCB.prototype.text = function (value) {
        var node;
        this.openCurrent();
        node = new XMLText(this || _global, value);
        this.onData((this || _global).writer.text(node, (this || _global).currentLevel + 1), (this || _global).currentLevel + 1);
        return this || _global;
      };

      XMLDocumentCB.prototype.cdata = function (value) {
        var node;
        this.openCurrent();
        node = new XMLCData(this || _global, value);
        this.onData((this || _global).writer.cdata(node, (this || _global).currentLevel + 1), (this || _global).currentLevel + 1);
        return this || _global;
      };

      XMLDocumentCB.prototype.comment = function (value) {
        var node;
        this.openCurrent();
        node = new XMLComment(this || _global, value);
        this.onData((this || _global).writer.comment(node, (this || _global).currentLevel + 1), (this || _global).currentLevel + 1);
        return this || _global;
      };

      XMLDocumentCB.prototype.raw = function (value) {
        var node;
        this.openCurrent();
        node = new XMLRaw(this || _global, value);
        this.onData((this || _global).writer.raw(node, (this || _global).currentLevel + 1), (this || _global).currentLevel + 1);
        return this || _global;
      };

      XMLDocumentCB.prototype.instruction = function (target, value) {
        var i, insTarget, insValue, len, node;
        this.openCurrent();

        if (target != null) {
          target = getValue(target);
        }

        if (value != null) {
          value = getValue(value);
        }

        if (Array.isArray(target)) {
          for (i = 0, len = target.length; i < len; i++) {
            insTarget = target[i];
            this.instruction(insTarget);
          }
        } else if (isObject(target)) {
          for (insTarget in target) {
            if (!hasProp.call(target, insTarget)) continue;
            insValue = target[insTarget];
            this.instruction(insTarget, insValue);
          }
        } else {
          if (isFunction(value)) {
            value = value.apply();
          }

          node = new XMLProcessingInstruction(this || _global, target, value);
          this.onData((this || _global).writer.processingInstruction(node, (this || _global).currentLevel + 1), (this || _global).currentLevel + 1);
        }

        return this || _global;
      };

      XMLDocumentCB.prototype.declaration = function (version, encoding, standalone) {
        var node;
        this.openCurrent();

        if ((this || _global).documentStarted) {
          throw new Error("declaration() must be the first node.");
        }

        node = new XMLDeclaration(this || _global, version, encoding, standalone);
        this.onData((this || _global).writer.declaration(node, (this || _global).currentLevel + 1), (this || _global).currentLevel + 1);
        return this || _global;
      };

      XMLDocumentCB.prototype.doctype = function (root, pubID, sysID) {
        this.openCurrent();

        if (root == null) {
          throw new Error("Missing root node name.");
        }

        if ((this || _global).root) {
          throw new Error("dtd() must come before the root node.");
        }

        (this || _global).currentNode = new XMLDocType(this || _global, pubID, sysID);
        (this || _global).currentNode.rootNodeName = root;
        (this || _global).currentNode.children = false;
        (this || _global).currentLevel++;
        (this || _global).openTags[(this || _global).currentLevel] = (this || _global).currentNode;
        return this || _global;
      };

      XMLDocumentCB.prototype.dtdElement = function (name, value) {
        var node;
        this.openCurrent();
        node = new XMLDTDElement(this || _global, name, value);
        this.onData((this || _global).writer.dtdElement(node, (this || _global).currentLevel + 1), (this || _global).currentLevel + 1);
        return this || _global;
      };

      XMLDocumentCB.prototype.attList = function (elementName, attributeName, attributeType, defaultValueType, defaultValue) {
        var node;
        this.openCurrent();
        node = new XMLDTDAttList(this || _global, elementName, attributeName, attributeType, defaultValueType, defaultValue);
        this.onData((this || _global).writer.dtdAttList(node, (this || _global).currentLevel + 1), (this || _global).currentLevel + 1);
        return this || _global;
      };

      XMLDocumentCB.prototype.entity = function (name, value) {
        var node;
        this.openCurrent();
        node = new XMLDTDEntity(this || _global, false, name, value);
        this.onData((this || _global).writer.dtdEntity(node, (this || _global).currentLevel + 1), (this || _global).currentLevel + 1);
        return this || _global;
      };

      XMLDocumentCB.prototype.pEntity = function (name, value) {
        var node;
        this.openCurrent();
        node = new XMLDTDEntity(this || _global, true, name, value);
        this.onData((this || _global).writer.dtdEntity(node, (this || _global).currentLevel + 1), (this || _global).currentLevel + 1);
        return this || _global;
      };

      XMLDocumentCB.prototype.notation = function (name, value) {
        var node;
        this.openCurrent();
        node = new XMLDTDNotation(this || _global, name, value);
        this.onData((this || _global).writer.dtdNotation(node, (this || _global).currentLevel + 1), (this || _global).currentLevel + 1);
        return this || _global;
      };

      XMLDocumentCB.prototype.up = function () {
        if ((this || _global).currentLevel < 0) {
          throw new Error("The document node has no parent.");
        }

        if ((this || _global).currentNode) {
          if ((this || _global).currentNode.children) {
            this.closeNode((this || _global).currentNode);
          } else {
            this.openNode((this || _global).currentNode);
          }

          (this || _global).currentNode = null;
        } else {
          this.closeNode((this || _global).openTags[(this || _global).currentLevel]);
        }

        delete (this || _global).openTags[(this || _global).currentLevel];
        (this || _global).currentLevel--;
        return this || _global;
      };

      XMLDocumentCB.prototype.end = function () {
        while ((this || _global).currentLevel >= 0) {
          this.up();
        }

        return this.onEnd();
      };

      XMLDocumentCB.prototype.openCurrent = function () {
        if ((this || _global).currentNode) {
          (this || _global).currentNode.children = true;
          return this.openNode((this || _global).currentNode);
        }
      };

      XMLDocumentCB.prototype.openNode = function (node) {
        if (!node.isOpen) {
          if (!(this || _global).root && (this || _global).currentLevel === 0 && node instanceof XMLElement) {
            (this || _global).root = node;
          }

          this.onData((this || _global).writer.openNode(node, (this || _global).currentLevel), (this || _global).currentLevel);
          return node.isOpen = true;
        }
      };

      XMLDocumentCB.prototype.closeNode = function (node) {
        if (!node.isClosed) {
          this.onData((this || _global).writer.closeNode(node, (this || _global).currentLevel), (this || _global).currentLevel);
          return node.isClosed = true;
        }
      };

      XMLDocumentCB.prototype.onData = function (chunk, level) {
        (this || _global).documentStarted = true;
        return this.onDataCallback(chunk, level + 1);
      };

      XMLDocumentCB.prototype.onEnd = function () {
        (this || _global).documentCompleted = true;
        return this.onEndCallback();
      };

      XMLDocumentCB.prototype.debugInfo = function (name) {
        if (name == null) {
          return "";
        } else {
          return "node: <" + name + ">";
        }
      };

      XMLDocumentCB.prototype.ele = function () {
        return (this || _global).element.apply(this || _global, arguments);
      };

      XMLDocumentCB.prototype.nod = function (name, attributes, text) {
        return this.node(name, attributes, text);
      };

      XMLDocumentCB.prototype.txt = function (value) {
        return this.text(value);
      };

      XMLDocumentCB.prototype.dat = function (value) {
        return this.cdata(value);
      };

      XMLDocumentCB.prototype.com = function (value) {
        return this.comment(value);
      };

      XMLDocumentCB.prototype.ins = function (target, value) {
        return this.instruction(target, value);
      };

      XMLDocumentCB.prototype.dec = function (version, encoding, standalone) {
        return this.declaration(version, encoding, standalone);
      };

      XMLDocumentCB.prototype.dtd = function (root, pubID, sysID) {
        return this.doctype(root, pubID, sysID);
      };

      XMLDocumentCB.prototype.e = function (name, attributes, text) {
        return this.element(name, attributes, text);
      };

      XMLDocumentCB.prototype.n = function (name, attributes, text) {
        return this.node(name, attributes, text);
      };

      XMLDocumentCB.prototype.t = function (value) {
        return this.text(value);
      };

      XMLDocumentCB.prototype.d = function (value) {
        return this.cdata(value);
      };

      XMLDocumentCB.prototype.c = function (value) {
        return this.comment(value);
      };

      XMLDocumentCB.prototype.r = function (value) {
        return this.raw(value);
      };

      XMLDocumentCB.prototype.i = function (target, value) {
        return this.instruction(target, value);
      };

      XMLDocumentCB.prototype.att = function () {
        if ((this || _global).currentNode && (this || _global).currentNode instanceof XMLDocType) {
          return (this || _global).attList.apply(this || _global, arguments);
        } else {
          return (this || _global).attribute.apply(this || _global, arguments);
        }
      };

      XMLDocumentCB.prototype.a = function () {
        if ((this || _global).currentNode && (this || _global).currentNode instanceof XMLDocType) {
          return (this || _global).attList.apply(this || _global, arguments);
        } else {
          return (this || _global).attribute.apply(this || _global, arguments);
        }
      };

      XMLDocumentCB.prototype.ent = function (name, value) {
        return this.entity(name, value);
      };

      XMLDocumentCB.prototype.pent = function (name, value) {
        return this.pEntity(name, value);
      };

      XMLDocumentCB.prototype.not = function (name, value) {
        return this.notation(name, value);
      };

      return XMLDocumentCB;
    }();
  }).call(exports);
  return exports;
}