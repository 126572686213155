import { dew as _UtilityDew } from "./Utility";
import { dew as _XMLNodeDew } from "./XMLNode";
import { dew as _XMLStringifierDew } from "./XMLStringifier";
import { dew as _XMLStringWriterDew } from "./XMLStringWriter";
var exports = {},
    _dewExec = false;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  // Generated by CoffeeScript 1.12.7
  (function () {
    var XMLDocument,
        XMLNode,
        XMLStringWriter,
        XMLStringifier,
        isPlainObject,
        extend = function (child, parent) {
      for (var key in parent) {
        if (hasProp.call(parent, key)) child[key] = parent[key];
      }

      function ctor() {
        (this || _global).constructor = child;
      }

      ctor.prototype = parent.prototype;
      child.prototype = new ctor();
      child.__super__ = parent.prototype;
      return child;
    },
        hasProp = {}.hasOwnProperty;

    isPlainObject = _UtilityDew().isPlainObject;
    XMLNode = _XMLNodeDew();
    XMLStringifier = _XMLStringifierDew();
    XMLStringWriter = _XMLStringWriterDew();

    exports = XMLDocument = function (superClass) {
      extend(XMLDocument, superClass);

      function XMLDocument(options) {
        XMLDocument.__super__.constructor.call(this || _global, null);

        (this || _global).name = "?xml";
        options || (options = {});

        if (!options.writer) {
          options.writer = new XMLStringWriter();
        }

        (this || _global).options = options;
        (this || _global).stringify = new XMLStringifier(options);
        (this || _global).isDocument = true;
      }

      XMLDocument.prototype.end = function (writer) {
        var writerOptions;

        if (!writer) {
          writer = (this || _global).options.writer;
        } else if (isPlainObject(writer)) {
          writerOptions = writer;
          writer = (this || _global).options.writer.set(writerOptions);
        }

        return writer.document(this || _global);
      };

      XMLDocument.prototype.toString = function (options) {
        return (this || _global).options.writer.set(options).document(this || _global);
      };

      return XMLDocument;
    }(XMLNode);
  }).call(exports);
  return exports;
}