var exports = {},
    _dewExec = false;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  // Generated by CoffeeScript 1.12.7
  (function () {
    var XMLWriterBase,
        hasProp = {}.hasOwnProperty;

    exports = XMLWriterBase = function () {
      function XMLWriterBase(options) {
        var key, ref, ref1, ref2, ref3, ref4, ref5, ref6, value;
        options || (options = {});
        (this || _global).pretty = options.pretty || false;
        (this || _global).allowEmpty = (ref = options.allowEmpty) != null ? ref : false;

        if ((this || _global).pretty) {
          (this || _global).indent = (ref1 = options.indent) != null ? ref1 : "  ";
          (this || _global).newline = (ref2 = options.newline) != null ? ref2 : "\n";
          (this || _global).offset = (ref3 = options.offset) != null ? ref3 : 0;
          (this || _global).dontprettytextnodes = (ref4 = options.dontprettytextnodes) != null ? ref4 : 0;
        } else {
          (this || _global).indent = "";
          (this || _global).newline = "";
          (this || _global).offset = 0;
          (this || _global).dontprettytextnodes = 0;
        }

        (this || _global).spacebeforeslash = (ref5 = options.spacebeforeslash) != null ? ref5 : "";

        if ((this || _global).spacebeforeslash === true) {
          (this || _global).spacebeforeslash = " ";
        }

        (this || _global).newlinedefault = (this || _global).newline;
        (this || _global).prettydefault = (this || _global).pretty;
        ref6 = options.writer || {};

        for (key in ref6) {
          if (!hasProp.call(ref6, key)) continue;
          value = ref6[key];
          (this || _global)[key] = value;
        }
      }

      XMLWriterBase.prototype.set = function (options) {
        var key, ref, value;
        options || (options = {});

        if ("pretty" in options) {
          (this || _global).pretty = options.pretty;
        }

        if ("allowEmpty" in options) {
          (this || _global).allowEmpty = options.allowEmpty;
        }

        if ((this || _global).pretty) {
          (this || _global).indent = "indent" in options ? options.indent : "  ";
          (this || _global).newline = "newline" in options ? options.newline : "\n";
          (this || _global).offset = "offset" in options ? options.offset : 0;
          (this || _global).dontprettytextnodes = "dontprettytextnodes" in options ? options.dontprettytextnodes : 0;
        } else {
          (this || _global).indent = "";
          (this || _global).newline = "";
          (this || _global).offset = 0;
          (this || _global).dontprettytextnodes = 0;
        }

        (this || _global).spacebeforeslash = "spacebeforeslash" in options ? options.spacebeforeslash : "";

        if ((this || _global).spacebeforeslash === true) {
          (this || _global).spacebeforeslash = " ";
        }

        (this || _global).newlinedefault = (this || _global).newline;
        (this || _global).prettydefault = (this || _global).pretty;
        ref = options.writer || {};

        for (key in ref) {
          if (!hasProp.call(ref, key)) continue;
          value = ref[key];
          (this || _global)[key] = value;
        }

        return this || _global;
      };

      XMLWriterBase.prototype.space = function (level) {
        var indent;

        if ((this || _global).pretty) {
          indent = (level || 0) + (this || _global).offset + 1;

          if (indent > 0) {
            return new Array(indent).join((this || _global).indent);
          } else {
            return "";
          }
        } else {
          return "";
        }
      };

      return XMLWriterBase;
    }();
  }).call(exports);
  return exports;
}