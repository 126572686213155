import { dew as _XMLNodeDew } from "./XMLNode";
var exports = {},
    _dewExec = false;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  // Generated by CoffeeScript 1.12.7
  (function () {
    var XMLDTDNotation,
        XMLNode,
        extend = function (child, parent) {
      for (var key in parent) {
        if (hasProp.call(parent, key)) child[key] = parent[key];
      }

      function ctor() {
        (this || _global).constructor = child;
      }

      ctor.prototype = parent.prototype;
      child.prototype = new ctor();
      child.__super__ = parent.prototype;
      return child;
    },
        hasProp = {}.hasOwnProperty;

    XMLNode = _XMLNodeDew();

    exports = XMLDTDNotation = function (superClass) {
      extend(XMLDTDNotation, superClass);

      function XMLDTDNotation(parent, name, value) {
        XMLDTDNotation.__super__.constructor.call(this || _global, parent);

        if (name == null) {
          throw new Error("Missing DTD notation name. " + this.debugInfo(name));
        }

        if (!value.pubID && !value.sysID) {
          throw new Error("Public or system identifiers are required for an external entity. " + this.debugInfo(name));
        }

        (this || _global).name = (this || _global).stringify.eleName(name);

        if (value.pubID != null) {
          (this || _global).pubID = (this || _global).stringify.dtdPubID(value.pubID);
        }

        if (value.sysID != null) {
          (this || _global).sysID = (this || _global).stringify.dtdSysID(value.sysID);
        }
      }

      XMLDTDNotation.prototype.toString = function (options) {
        return (this || _global).options.writer.set(options).dtdNotation(this || _global);
      };

      return XMLDTDNotation;
    }(XMLNode);
  }).call(exports);
  return exports;
}