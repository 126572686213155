import { dew as _XMLDeclarationDew } from "./XMLDeclaration";
import { dew as _XMLDocTypeDew } from "./XMLDocType";
import { dew as _XMLCDataDew } from "./XMLCData";
import { dew as _XMLCommentDew } from "./XMLComment";
import { dew as _XMLElementDew } from "./XMLElement";
import { dew as _XMLRawDew } from "./XMLRaw";
import { dew as _XMLTextDew } from "./XMLText";
import { dew as _XMLProcessingInstructionDew } from "./XMLProcessingInstruction";
import { dew as _XMLDummyDew } from "./XMLDummy";
import { dew as _XMLDTDAttListDew } from "./XMLDTDAttList";
import { dew as _XMLDTDElementDew } from "./XMLDTDElement";
import { dew as _XMLDTDEntityDew } from "./XMLDTDEntity";
import { dew as _XMLDTDNotationDew } from "./XMLDTDNotation";
import { dew as _XMLWriterBaseDew } from "./XMLWriterBase";
var exports = {},
    _dewExec = false;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  // Generated by CoffeeScript 1.12.7
  (function () {
    var XMLCData,
        XMLComment,
        XMLDTDAttList,
        XMLDTDElement,
        XMLDTDEntity,
        XMLDTDNotation,
        XMLDeclaration,
        XMLDocType,
        XMLDummy,
        XMLElement,
        XMLProcessingInstruction,
        XMLRaw,
        XMLStreamWriter,
        XMLText,
        XMLWriterBase,
        extend = function (child, parent) {
      for (var key in parent) {
        if (hasProp.call(parent, key)) child[key] = parent[key];
      }

      function ctor() {
        (this || _global).constructor = child;
      }

      ctor.prototype = parent.prototype;
      child.prototype = new ctor();
      child.__super__ = parent.prototype;
      return child;
    },
        hasProp = {}.hasOwnProperty;

    XMLDeclaration = _XMLDeclarationDew();
    XMLDocType = _XMLDocTypeDew();
    XMLCData = _XMLCDataDew();
    XMLComment = _XMLCommentDew();
    XMLElement = _XMLElementDew();
    XMLRaw = _XMLRawDew();
    XMLText = _XMLTextDew();
    XMLProcessingInstruction = _XMLProcessingInstructionDew();
    XMLDummy = _XMLDummyDew();
    XMLDTDAttList = _XMLDTDAttListDew();
    XMLDTDElement = _XMLDTDElementDew();
    XMLDTDEntity = _XMLDTDEntityDew();
    XMLDTDNotation = _XMLDTDNotationDew();
    XMLWriterBase = _XMLWriterBaseDew();

    exports = XMLStreamWriter = function (superClass) {
      extend(XMLStreamWriter, superClass);

      function XMLStreamWriter(stream, options) {
        XMLStreamWriter.__super__.constructor.call(this || _global, options);

        (this || _global).stream = stream;
      }

      XMLStreamWriter.prototype.document = function (doc) {
        var child, i, j, len, len1, ref, ref1, results;
        ref = doc.children;

        for (i = 0, len = ref.length; i < len; i++) {
          child = ref[i];
          child.isLastRootNode = false;
        }

        doc.children[doc.children.length - 1].isLastRootNode = true;
        ref1 = doc.children;
        results = [];

        for (j = 0, len1 = ref1.length; j < len1; j++) {
          child = ref1[j];

          if (child instanceof XMLDummy) {
            continue;
          }

          switch (false) {
            case !(child instanceof XMLDeclaration):
              results.push(this.declaration(child));
              break;

            case !(child instanceof XMLDocType):
              results.push(this.docType(child));
              break;

            case !(child instanceof XMLComment):
              results.push(this.comment(child));
              break;

            case !(child instanceof XMLProcessingInstruction):
              results.push(this.processingInstruction(child));
              break;

            default:
              results.push(this.element(child));
          }
        }

        return results;
      };

      XMLStreamWriter.prototype.attribute = function (att) {
        return (this || _global).stream.write(" " + att.name + "=\"" + att.value + "\"");
      };

      XMLStreamWriter.prototype.cdata = function (node, level) {
        return (this || _global).stream.write(this.space(level) + "<![CDATA[" + node.text + "]]>" + this.endline(node));
      };

      XMLStreamWriter.prototype.comment = function (node, level) {
        return (this || _global).stream.write(this.space(level) + "<!-- " + node.text + " -->" + this.endline(node));
      };

      XMLStreamWriter.prototype.declaration = function (node, level) {
        (this || _global).stream.write(this.space(level));

        (this || _global).stream.write("<?xml version=\"" + node.version + "\"");

        if (node.encoding != null) {
          (this || _global).stream.write(" encoding=\"" + node.encoding + "\"");
        }

        if (node.standalone != null) {
          (this || _global).stream.write(" standalone=\"" + node.standalone + "\"");
        }

        (this || _global).stream.write((this || _global).spacebeforeslash + "?>");

        return (this || _global).stream.write(this.endline(node));
      };

      XMLStreamWriter.prototype.docType = function (node, level) {
        var child, i, len, ref;
        level || (level = 0);

        (this || _global).stream.write(this.space(level));

        (this || _global).stream.write("<!DOCTYPE " + node.root().name);

        if (node.pubID && node.sysID) {
          (this || _global).stream.write(" PUBLIC \"" + node.pubID + "\" \"" + node.sysID + "\"");
        } else if (node.sysID) {
          (this || _global).stream.write(" SYSTEM \"" + node.sysID + "\"");
        }

        if (node.children.length > 0) {
          (this || _global).stream.write(" [");

          (this || _global).stream.write(this.endline(node));

          ref = node.children;

          for (i = 0, len = ref.length; i < len; i++) {
            child = ref[i];

            switch (false) {
              case !(child instanceof XMLDTDAttList):
                this.dtdAttList(child, level + 1);
                break;

              case !(child instanceof XMLDTDElement):
                this.dtdElement(child, level + 1);
                break;

              case !(child instanceof XMLDTDEntity):
                this.dtdEntity(child, level + 1);
                break;

              case !(child instanceof XMLDTDNotation):
                this.dtdNotation(child, level + 1);
                break;

              case !(child instanceof XMLCData):
                this.cdata(child, level + 1);
                break;

              case !(child instanceof XMLComment):
                this.comment(child, level + 1);
                break;

              case !(child instanceof XMLProcessingInstruction):
                this.processingInstruction(child, level + 1);
                break;

              default:
                throw new Error("Unknown DTD node type: " + child.constructor.name);
            }
          }

          (this || _global).stream.write("]");
        }

        (this || _global).stream.write((this || _global).spacebeforeslash + ">");

        return (this || _global).stream.write(this.endline(node));
      };

      XMLStreamWriter.prototype.element = function (node, level) {
        var att, child, i, len, name, ref, ref1, space;
        level || (level = 0);
        space = this.space(level);

        (this || _global).stream.write(space + "<" + node.name);

        ref = node.attributes;

        for (name in ref) {
          if (!hasProp.call(ref, name)) continue;
          att = ref[name];
          this.attribute(att);
        }

        if (node.children.length === 0 || node.children.every(function (e) {
          return e.value === "";
        })) {
          if ((this || _global).allowEmpty) {
            (this || _global).stream.write("></" + node.name + ">");
          } else {
            (this || _global).stream.write((this || _global).spacebeforeslash + "/>");
          }
        } else if ((this || _global).pretty && node.children.length === 1 && node.children[0].value != null) {
          (this || _global).stream.write(">");

          (this || _global).stream.write(node.children[0].value);

          (this || _global).stream.write("</" + node.name + ">");
        } else {
          (this || _global).stream.write(">" + (this || _global).newline);

          ref1 = node.children;

          for (i = 0, len = ref1.length; i < len; i++) {
            child = ref1[i];

            switch (false) {
              case !(child instanceof XMLCData):
                this.cdata(child, level + 1);
                break;

              case !(child instanceof XMLComment):
                this.comment(child, level + 1);
                break;

              case !(child instanceof XMLElement):
                this.element(child, level + 1);
                break;

              case !(child instanceof XMLRaw):
                this.raw(child, level + 1);
                break;

              case !(child instanceof XMLText):
                this.text(child, level + 1);
                break;

              case !(child instanceof XMLProcessingInstruction):
                this.processingInstruction(child, level + 1);
                break;

              case !(child instanceof XMLDummy):
                "";
                break;

              default:
                throw new Error("Unknown XML node type: " + child.constructor.name);
            }
          }

          (this || _global).stream.write(space + "</" + node.name + ">");
        }

        return (this || _global).stream.write(this.endline(node));
      };

      XMLStreamWriter.prototype.processingInstruction = function (node, level) {
        (this || _global).stream.write(this.space(level) + "<?" + node.target);

        if (node.value) {
          (this || _global).stream.write(" " + node.value);
        }

        return (this || _global).stream.write((this || _global).spacebeforeslash + "?>" + this.endline(node));
      };

      XMLStreamWriter.prototype.raw = function (node, level) {
        return (this || _global).stream.write(this.space(level) + node.value + this.endline(node));
      };

      XMLStreamWriter.prototype.text = function (node, level) {
        return (this || _global).stream.write(this.space(level) + node.value + this.endline(node));
      };

      XMLStreamWriter.prototype.dtdAttList = function (node, level) {
        (this || _global).stream.write(this.space(level) + "<!ATTLIST " + node.elementName + " " + node.attributeName + " " + node.attributeType);

        if (node.defaultValueType !== "#DEFAULT") {
          (this || _global).stream.write(" " + node.defaultValueType);
        }

        if (node.defaultValue) {
          (this || _global).stream.write(" \"" + node.defaultValue + "\"");
        }

        return (this || _global).stream.write((this || _global).spacebeforeslash + ">" + this.endline(node));
      };

      XMLStreamWriter.prototype.dtdElement = function (node, level) {
        (this || _global).stream.write(this.space(level) + "<!ELEMENT " + node.name + " " + node.value);

        return (this || _global).stream.write((this || _global).spacebeforeslash + ">" + this.endline(node));
      };

      XMLStreamWriter.prototype.dtdEntity = function (node, level) {
        (this || _global).stream.write(this.space(level) + "<!ENTITY");

        if (node.pe) {
          (this || _global).stream.write(" %");
        }

        (this || _global).stream.write(" " + node.name);

        if (node.value) {
          (this || _global).stream.write(" \"" + node.value + "\"");
        } else {
          if (node.pubID && node.sysID) {
            (this || _global).stream.write(" PUBLIC \"" + node.pubID + "\" \"" + node.sysID + "\"");
          } else if (node.sysID) {
            (this || _global).stream.write(" SYSTEM \"" + node.sysID + "\"");
          }

          if (node.nData) {
            (this || _global).stream.write(" NDATA " + node.nData);
          }
        }

        return (this || _global).stream.write((this || _global).spacebeforeslash + ">" + this.endline(node));
      };

      XMLStreamWriter.prototype.dtdNotation = function (node, level) {
        (this || _global).stream.write(this.space(level) + "<!NOTATION " + node.name);

        if (node.pubID && node.sysID) {
          (this || _global).stream.write(" PUBLIC \"" + node.pubID + "\" \"" + node.sysID + "\"");
        } else if (node.pubID) {
          (this || _global).stream.write(" PUBLIC \"" + node.pubID + "\"");
        } else if (node.sysID) {
          (this || _global).stream.write(" SYSTEM \"" + node.sysID + "\"");
        }

        return (this || _global).stream.write((this || _global).spacebeforeslash + ">" + this.endline(node));
      };

      XMLStreamWriter.prototype.endline = function (node) {
        if (!node.isLastRootNode) {
          return (this || _global).newline;
        } else {
          return "";
        }
      };

      return XMLStreamWriter;
    }(XMLWriterBase);
  }).call(exports);
  return exports;
}