import { dew as _UtilityDew } from "./Utility";
import { dew as _XMLNodeDew } from "./XMLNode";
var exports = {},
    _dewExec = false;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  // Generated by CoffeeScript 1.12.7
  (function () {
    var XMLDeclaration,
        XMLNode,
        isObject,
        extend = function (child, parent) {
      for (var key in parent) {
        if (hasProp.call(parent, key)) child[key] = parent[key];
      }

      function ctor() {
        (this || _global).constructor = child;
      }

      ctor.prototype = parent.prototype;
      child.prototype = new ctor();
      child.__super__ = parent.prototype;
      return child;
    },
        hasProp = {}.hasOwnProperty;

    isObject = _UtilityDew().isObject;
    XMLNode = _XMLNodeDew();

    exports = XMLDeclaration = function (superClass) {
      extend(XMLDeclaration, superClass);

      function XMLDeclaration(parent, version, encoding, standalone) {
        var ref;

        XMLDeclaration.__super__.constructor.call(this || _global, parent);

        if (isObject(version)) {
          ref = version, version = ref.version, encoding = ref.encoding, standalone = ref.standalone;
        }

        if (!version) {
          version = "1.0";
        }

        (this || _global).version = (this || _global).stringify.xmlVersion(version);

        if (encoding != null) {
          (this || _global).encoding = (this || _global).stringify.xmlEncoding(encoding);
        }

        if (standalone != null) {
          (this || _global).standalone = (this || _global).stringify.xmlStandalone(standalone);
        }
      }

      XMLDeclaration.prototype.toString = function (options) {
        return (this || _global).options.writer.set(options).declaration(this || _global);
      };

      return XMLDeclaration;
    }(XMLNode);
  }).call(exports);
  return exports;
}