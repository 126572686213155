import { dew as _XMLNodeDew } from "./XMLNode";
var exports = {},
    _dewExec = false;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  // Generated by CoffeeScript 1.12.7
  (function () {
    var XMLCData,
        XMLNode,
        extend = function (child, parent) {
      for (var key in parent) {
        if (hasProp.call(parent, key)) child[key] = parent[key];
      }

      function ctor() {
        (this || _global).constructor = child;
      }

      ctor.prototype = parent.prototype;
      child.prototype = new ctor();
      child.__super__ = parent.prototype;
      return child;
    },
        hasProp = {}.hasOwnProperty;

    XMLNode = _XMLNodeDew();

    exports = XMLCData = function (superClass) {
      extend(XMLCData, superClass);

      function XMLCData(parent, text) {
        XMLCData.__super__.constructor.call(this || _global, parent);

        if (text == null) {
          throw new Error("Missing CDATA text. " + this.debugInfo());
        }

        (this || _global).text = (this || _global).stringify.cdata(text);
      }

      XMLCData.prototype.clone = function () {
        return Object.create(this || _global);
      };

      XMLCData.prototype.toString = function (options) {
        return (this || _global).options.writer.set(options).cdata(this || _global);
      };

      return XMLCData;
    }(XMLNode);
  }).call(exports);
  return exports;
}