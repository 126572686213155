import { dew as _XMLDeclarationDew } from "./XMLDeclaration";
import { dew as _XMLDocTypeDew } from "./XMLDocType";
import { dew as _XMLCDataDew } from "./XMLCData";
import { dew as _XMLCommentDew } from "./XMLComment";
import { dew as _XMLElementDew } from "./XMLElement";
import { dew as _XMLRawDew } from "./XMLRaw";
import { dew as _XMLTextDew } from "./XMLText";
import { dew as _XMLProcessingInstructionDew } from "./XMLProcessingInstruction";
import { dew as _XMLDummyDew } from "./XMLDummy";
import { dew as _XMLDTDAttListDew } from "./XMLDTDAttList";
import { dew as _XMLDTDElementDew } from "./XMLDTDElement";
import { dew as _XMLDTDEntityDew } from "./XMLDTDEntity";
import { dew as _XMLDTDNotationDew } from "./XMLDTDNotation";
import { dew as _XMLWriterBaseDew } from "./XMLWriterBase";
var exports = {},
    _dewExec = false;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  // Generated by CoffeeScript 1.12.7
  (function () {
    var XMLCData,
        XMLComment,
        XMLDTDAttList,
        XMLDTDElement,
        XMLDTDEntity,
        XMLDTDNotation,
        XMLDeclaration,
        XMLDocType,
        XMLDummy,
        XMLElement,
        XMLProcessingInstruction,
        XMLRaw,
        XMLStringWriter,
        XMLText,
        XMLWriterBase,
        extend = function (child, parent) {
      for (var key in parent) {
        if (hasProp.call(parent, key)) child[key] = parent[key];
      }

      function ctor() {
        (this || _global).constructor = child;
      }

      ctor.prototype = parent.prototype;
      child.prototype = new ctor();
      child.__super__ = parent.prototype;
      return child;
    },
        hasProp = {}.hasOwnProperty;

    XMLDeclaration = _XMLDeclarationDew();
    XMLDocType = _XMLDocTypeDew();
    XMLCData = _XMLCDataDew();
    XMLComment = _XMLCommentDew();
    XMLElement = _XMLElementDew();
    XMLRaw = _XMLRawDew();
    XMLText = _XMLTextDew();
    XMLProcessingInstruction = _XMLProcessingInstructionDew();
    XMLDummy = _XMLDummyDew();
    XMLDTDAttList = _XMLDTDAttListDew();
    XMLDTDElement = _XMLDTDElementDew();
    XMLDTDEntity = _XMLDTDEntityDew();
    XMLDTDNotation = _XMLDTDNotationDew();
    XMLWriterBase = _XMLWriterBaseDew();

    exports = XMLStringWriter = function (superClass) {
      extend(XMLStringWriter, superClass);

      function XMLStringWriter(options) {
        XMLStringWriter.__super__.constructor.call(this || _global, options);
      }

      XMLStringWriter.prototype.document = function (doc) {
        var child, i, len, r, ref;
        (this || _global).textispresent = false;
        r = "";
        ref = doc.children;

        for (i = 0, len = ref.length; i < len; i++) {
          child = ref[i];

          if (child instanceof XMLDummy) {
            continue;
          }

          r += function () {
            switch (false) {
              case !(child instanceof XMLDeclaration):
                return this.declaration(child);

              case !(child instanceof XMLDocType):
                return this.docType(child);

              case !(child instanceof XMLComment):
                return this.comment(child);

              case !(child instanceof XMLProcessingInstruction):
                return this.processingInstruction(child);

              default:
                return this.element(child, 0);
            }
          }.call(this || _global);
        }

        if ((this || _global).pretty && r.slice(-(this || _global).newline.length) === (this || _global).newline) {
          r = r.slice(0, -(this || _global).newline.length);
        }

        return r;
      };

      XMLStringWriter.prototype.attribute = function (att) {
        return " " + att.name + "=\"" + att.value + "\"";
      };

      XMLStringWriter.prototype.cdata = function (node, level) {
        return this.space(level) + "<![CDATA[" + node.text + "]]>" + (this || _global).newline;
      };

      XMLStringWriter.prototype.comment = function (node, level) {
        return this.space(level) + "<!-- " + node.text + " -->" + (this || _global).newline;
      };

      XMLStringWriter.prototype.declaration = function (node, level) {
        var r;
        r = this.space(level);
        r += "<?xml version=\"" + node.version + "\"";

        if (node.encoding != null) {
          r += " encoding=\"" + node.encoding + "\"";
        }

        if (node.standalone != null) {
          r += " standalone=\"" + node.standalone + "\"";
        }

        r += (this || _global).spacebeforeslash + "?>";
        r += (this || _global).newline;
        return r;
      };

      XMLStringWriter.prototype.docType = function (node, level) {
        var child, i, len, r, ref;
        level || (level = 0);
        r = this.space(level);
        r += "<!DOCTYPE " + node.root().name;

        if (node.pubID && node.sysID) {
          r += " PUBLIC \"" + node.pubID + "\" \"" + node.sysID + "\"";
        } else if (node.sysID) {
          r += " SYSTEM \"" + node.sysID + "\"";
        }

        if (node.children.length > 0) {
          r += " [";
          r += (this || _global).newline;
          ref = node.children;

          for (i = 0, len = ref.length; i < len; i++) {
            child = ref[i];

            r += function () {
              switch (false) {
                case !(child instanceof XMLDTDAttList):
                  return this.dtdAttList(child, level + 1);

                case !(child instanceof XMLDTDElement):
                  return this.dtdElement(child, level + 1);

                case !(child instanceof XMLDTDEntity):
                  return this.dtdEntity(child, level + 1);

                case !(child instanceof XMLDTDNotation):
                  return this.dtdNotation(child, level + 1);

                case !(child instanceof XMLCData):
                  return this.cdata(child, level + 1);

                case !(child instanceof XMLComment):
                  return this.comment(child, level + 1);

                case !(child instanceof XMLProcessingInstruction):
                  return this.processingInstruction(child, level + 1);

                default:
                  throw new Error("Unknown DTD node type: " + child.constructor.name);
              }
            }.call(this || _global);
          }

          r += "]";
        }

        r += (this || _global).spacebeforeslash + ">";
        r += (this || _global).newline;
        return r;
      };

      XMLStringWriter.prototype.element = function (node, level) {
        var att, child, i, j, len, len1, name, r, ref, ref1, ref2, space, textispresentwasset;
        level || (level = 0);
        textispresentwasset = false;

        if ((this || _global).textispresent) {
          (this || _global).newline = "";
          (this || _global).pretty = false;
        } else {
          (this || _global).newline = (this || _global).newlinedefault;
          (this || _global).pretty = (this || _global).prettydefault;
        }

        space = this.space(level);
        r = "";
        r += space + "<" + node.name;
        ref = node.attributes;

        for (name in ref) {
          if (!hasProp.call(ref, name)) continue;
          att = ref[name];
          r += this.attribute(att);
        }

        if (node.children.length === 0 || node.children.every(function (e) {
          return e.value === "";
        })) {
          if ((this || _global).allowEmpty) {
            r += "></" + node.name + ">" + (this || _global).newline;
          } else {
            r += (this || _global).spacebeforeslash + "/>" + (this || _global).newline;
          }
        } else if ((this || _global).pretty && node.children.length === 1 && node.children[0].value != null) {
          r += ">";
          r += node.children[0].value;
          r += "</" + node.name + ">" + (this || _global).newline;
        } else {
          if ((this || _global).dontprettytextnodes) {
            ref1 = node.children;

            for (i = 0, len = ref1.length; i < len; i++) {
              child = ref1[i];

              if (child.value != null) {
                (this || _global).textispresent++;
                textispresentwasset = true;
                break;
              }
            }
          }

          if ((this || _global).textispresent) {
            (this || _global).newline = "";
            (this || _global).pretty = false;
            space = this.space(level);
          }

          r += ">" + (this || _global).newline;
          ref2 = node.children;

          for (j = 0, len1 = ref2.length; j < len1; j++) {
            child = ref2[j];

            r += function () {
              switch (false) {
                case !(child instanceof XMLCData):
                  return this.cdata(child, level + 1);

                case !(child instanceof XMLComment):
                  return this.comment(child, level + 1);

                case !(child instanceof XMLElement):
                  return this.element(child, level + 1);

                case !(child instanceof XMLRaw):
                  return this.raw(child, level + 1);

                case !(child instanceof XMLText):
                  return this.text(child, level + 1);

                case !(child instanceof XMLProcessingInstruction):
                  return this.processingInstruction(child, level + 1);

                case !(child instanceof XMLDummy):
                  return "";

                default:
                  throw new Error("Unknown XML node type: " + child.constructor.name);
              }
            }.call(this || _global);
          }

          if (textispresentwasset) {
            (this || _global).textispresent--;
          }

          if (!(this || _global).textispresent) {
            (this || _global).newline = (this || _global).newlinedefault;
            (this || _global).pretty = (this || _global).prettydefault;
          }

          r += space + "</" + node.name + ">" + (this || _global).newline;
        }

        return r;
      };

      XMLStringWriter.prototype.processingInstruction = function (node, level) {
        var r;
        r = this.space(level) + "<?" + node.target;

        if (node.value) {
          r += " " + node.value;
        }

        r += (this || _global).spacebeforeslash + "?>" + (this || _global).newline;
        return r;
      };

      XMLStringWriter.prototype.raw = function (node, level) {
        return this.space(level) + node.value + (this || _global).newline;
      };

      XMLStringWriter.prototype.text = function (node, level) {
        return this.space(level) + node.value + (this || _global).newline;
      };

      XMLStringWriter.prototype.dtdAttList = function (node, level) {
        var r;
        r = this.space(level) + "<!ATTLIST " + node.elementName + " " + node.attributeName + " " + node.attributeType;

        if (node.defaultValueType !== "#DEFAULT") {
          r += " " + node.defaultValueType;
        }

        if (node.defaultValue) {
          r += " \"" + node.defaultValue + "\"";
        }

        r += (this || _global).spacebeforeslash + ">" + (this || _global).newline;
        return r;
      };

      XMLStringWriter.prototype.dtdElement = function (node, level) {
        return this.space(level) + "<!ELEMENT " + node.name + " " + node.value + (this || _global).spacebeforeslash + ">" + (this || _global).newline;
      };

      XMLStringWriter.prototype.dtdEntity = function (node, level) {
        var r;
        r = this.space(level) + "<!ENTITY";

        if (node.pe) {
          r += " %";
        }

        r += " " + node.name;

        if (node.value) {
          r += " \"" + node.value + "\"";
        } else {
          if (node.pubID && node.sysID) {
            r += " PUBLIC \"" + node.pubID + "\" \"" + node.sysID + "\"";
          } else if (node.sysID) {
            r += " SYSTEM \"" + node.sysID + "\"";
          }

          if (node.nData) {
            r += " NDATA " + node.nData;
          }
        }

        r += (this || _global).spacebeforeslash + ">" + (this || _global).newline;
        return r;
      };

      XMLStringWriter.prototype.dtdNotation = function (node, level) {
        var r;
        r = this.space(level) + "<!NOTATION " + node.name;

        if (node.pubID && node.sysID) {
          r += " PUBLIC \"" + node.pubID + "\" \"" + node.sysID + "\"";
        } else if (node.pubID) {
          r += " PUBLIC \"" + node.pubID + "\"";
        } else if (node.sysID) {
          r += " SYSTEM \"" + node.sysID + "\"";
        }

        r += (this || _global).spacebeforeslash + ">" + (this || _global).newline;
        return r;
      };

      XMLStringWriter.prototype.openNode = function (node, level) {
        var att, name, r, ref;
        level || (level = 0);

        if (node instanceof XMLElement) {
          r = this.space(level) + "<" + node.name;
          ref = node.attributes;

          for (name in ref) {
            if (!hasProp.call(ref, name)) continue;
            att = ref[name];
            r += this.attribute(att);
          }

          r += (node.children ? ">" : "/>") + (this || _global).newline;
          return r;
        } else {
          r = this.space(level) + "<!DOCTYPE " + node.rootNodeName;

          if (node.pubID && node.sysID) {
            r += " PUBLIC \"" + node.pubID + "\" \"" + node.sysID + "\"";
          } else if (node.sysID) {
            r += " SYSTEM \"" + node.sysID + "\"";
          }

          r += (node.children ? " [" : ">") + (this || _global).newline;
          return r;
        }
      };

      XMLStringWriter.prototype.closeNode = function (node, level) {
        level || (level = 0);

        switch (false) {
          case !(node instanceof XMLElement):
            return this.space(level) + "</" + node.name + ">" + (this || _global).newline;

          case !(node instanceof XMLDocType):
            return this.space(level) + "]>" + (this || _global).newline;
        }
      };

      return XMLStringWriter;
    }(XMLWriterBase);
  }).call(exports);
  return exports;
}