import { dew as _UtilityDew } from "./Utility";
import { dew as _XMLNodeDew } from "./XMLNode";
import { dew as _XMLDTDAttListDew } from "./XMLDTDAttList";
import { dew as _XMLDTDEntityDew } from "./XMLDTDEntity";
import { dew as _XMLDTDElementDew } from "./XMLDTDElement";
import { dew as _XMLDTDNotationDew } from "./XMLDTDNotation";
var exports = {},
    _dewExec = false;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  // Generated by CoffeeScript 1.12.7
  (function () {
    var XMLDTDAttList,
        XMLDTDElement,
        XMLDTDEntity,
        XMLDTDNotation,
        XMLDocType,
        XMLNode,
        isObject,
        extend = function (child, parent) {
      for (var key in parent) {
        if (hasProp.call(parent, key)) child[key] = parent[key];
      }

      function ctor() {
        (this || _global).constructor = child;
      }

      ctor.prototype = parent.prototype;
      child.prototype = new ctor();
      child.__super__ = parent.prototype;
      return child;
    },
        hasProp = {}.hasOwnProperty;

    isObject = _UtilityDew().isObject;
    XMLNode = _XMLNodeDew();
    XMLDTDAttList = _XMLDTDAttListDew();
    XMLDTDEntity = _XMLDTDEntityDew();
    XMLDTDElement = _XMLDTDElementDew();
    XMLDTDNotation = _XMLDTDNotationDew();

    exports = XMLDocType = function (superClass) {
      extend(XMLDocType, superClass);

      function XMLDocType(parent, pubID, sysID) {
        var ref, ref1;

        XMLDocType.__super__.constructor.call(this || _global, parent);

        (this || _global).name = "!DOCTYPE";
        (this || _global).documentObject = parent;

        if (isObject(pubID)) {
          ref = pubID, pubID = ref.pubID, sysID = ref.sysID;
        }

        if (sysID == null) {
          ref1 = [pubID, sysID], sysID = ref1[0], pubID = ref1[1];
        }

        if (pubID != null) {
          (this || _global).pubID = (this || _global).stringify.dtdPubID(pubID);
        }

        if (sysID != null) {
          (this || _global).sysID = (this || _global).stringify.dtdSysID(sysID);
        }
      }

      XMLDocType.prototype.element = function (name, value) {
        var child;
        child = new XMLDTDElement(this || _global, name, value);

        (this || _global).children.push(child);

        return this || _global;
      };

      XMLDocType.prototype.attList = function (elementName, attributeName, attributeType, defaultValueType, defaultValue) {
        var child;
        child = new XMLDTDAttList(this || _global, elementName, attributeName, attributeType, defaultValueType, defaultValue);

        (this || _global).children.push(child);

        return this || _global;
      };

      XMLDocType.prototype.entity = function (name, value) {
        var child;
        child = new XMLDTDEntity(this || _global, false, name, value);

        (this || _global).children.push(child);

        return this || _global;
      };

      XMLDocType.prototype.pEntity = function (name, value) {
        var child;
        child = new XMLDTDEntity(this || _global, true, name, value);

        (this || _global).children.push(child);

        return this || _global;
      };

      XMLDocType.prototype.notation = function (name, value) {
        var child;
        child = new XMLDTDNotation(this || _global, name, value);

        (this || _global).children.push(child);

        return this || _global;
      };

      XMLDocType.prototype.toString = function (options) {
        return (this || _global).options.writer.set(options).docType(this || _global);
      };

      XMLDocType.prototype.ele = function (name, value) {
        return this.element(name, value);
      };

      XMLDocType.prototype.att = function (elementName, attributeName, attributeType, defaultValueType, defaultValue) {
        return this.attList(elementName, attributeName, attributeType, defaultValueType, defaultValue);
      };

      XMLDocType.prototype.ent = function (name, value) {
        return this.entity(name, value);
      };

      XMLDocType.prototype.pent = function (name, value) {
        return this.pEntity(name, value);
      };

      XMLDocType.prototype.not = function (name, value) {
        return this.notation(name, value);
      };

      XMLDocType.prototype.up = function () {
        return this.root() || (this || _global).documentObject;
      };

      return XMLDocType;
    }(XMLNode);
  }).call(exports);
  return exports;
}