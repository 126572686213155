var exports = {},
    _dewExec = false;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  // Generated by CoffeeScript 1.12.7
  (function () {
    var XMLAttribute;

    exports = XMLAttribute = function () {
      function XMLAttribute(parent, name, value) {
        (this || _global).options = parent.options;
        (this || _global).stringify = parent.stringify;
        (this || _global).parent = parent;

        if (name == null) {
          throw new Error("Missing attribute name. " + this.debugInfo(name));
        }

        if (value == null) {
          throw new Error("Missing attribute value. " + this.debugInfo(name));
        }

        (this || _global).name = (this || _global).stringify.attName(name);
        (this || _global).value = (this || _global).stringify.attValue(value);
      }

      XMLAttribute.prototype.clone = function () {
        return Object.create(this || _global);
      };

      XMLAttribute.prototype.toString = function (options) {
        return (this || _global).options.writer.set(options).attribute(this || _global);
      };

      XMLAttribute.prototype.debugInfo = function (name) {
        name = name || (this || _global).name;

        if (name == null) {
          return "parent: <" + (this || _global).parent.name + ">";
        } else {
          return "attribute: {" + name + "}, parent: <" + (this || _global).parent.name + ">";
        }
      };

      return XMLAttribute;
    }();
  }).call(exports);
  return exports;
}