import { dew as _UtilityDew } from "./Utility";
import { dew as _XMLElementDew } from "./XMLElement";
import { dew as _XMLCDataDew } from "./XMLCData";
import { dew as _XMLCommentDew } from "./XMLComment";
import { dew as _XMLDeclarationDew } from "./XMLDeclaration";
import { dew as _XMLDocTypeDew } from "./XMLDocType";
import { dew as _XMLRawDew } from "./XMLRaw";
import { dew as _XMLTextDew } from "./XMLText";
import { dew as _XMLProcessingInstructionDew } from "./XMLProcessingInstruction";
import { dew as _XMLDummyDew } from "./XMLDummy";
var exports = {},
    _dewExec = false;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  // Generated by CoffeeScript 1.12.7
  (function () {
    var XMLCData,
        XMLComment,
        XMLDeclaration,
        XMLDocType,
        XMLDummy,
        XMLElement,
        XMLNode,
        XMLProcessingInstruction,
        XMLRaw,
        XMLText,
        getValue,
        isEmpty,
        isFunction,
        isObject,
        ref,
        hasProp = {}.hasOwnProperty;
    ref = _UtilityDew(), isObject = ref.isObject, isFunction = ref.isFunction, isEmpty = ref.isEmpty, getValue = ref.getValue;
    XMLElement = null;
    XMLCData = null;
    XMLComment = null;
    XMLDeclaration = null;
    XMLDocType = null;
    XMLRaw = null;
    XMLText = null;
    XMLProcessingInstruction = null;
    XMLDummy = null;

    exports = XMLNode = function () {
      function XMLNode(parent) {
        (this || _global).parent = parent;

        if ((this || _global).parent) {
          (this || _global).options = (this || _global).parent.options;
          (this || _global).stringify = (this || _global).parent.stringify;
        }

        (this || _global).children = [];

        if (!XMLElement) {
          XMLElement = _XMLElementDew();
          XMLCData = _XMLCDataDew();
          XMLComment = _XMLCommentDew();
          XMLDeclaration = _XMLDeclarationDew();
          XMLDocType = _XMLDocTypeDew();
          XMLRaw = _XMLRawDew();
          XMLText = _XMLTextDew();
          XMLProcessingInstruction = _XMLProcessingInstructionDew();
          XMLDummy = _XMLDummyDew();
        }
      }

      XMLNode.prototype.element = function (name, attributes, text) {
        var childNode, item, j, k, key, lastChild, len, len1, ref1, ref2, val;
        lastChild = null;

        if (attributes === null && text == null) {
          ref1 = [{}, null], attributes = ref1[0], text = ref1[1];
        }

        if (attributes == null) {
          attributes = {};
        }

        attributes = getValue(attributes);

        if (!isObject(attributes)) {
          ref2 = [attributes, text], text = ref2[0], attributes = ref2[1];
        }

        if (name != null) {
          name = getValue(name);
        }

        if (Array.isArray(name)) {
          for (j = 0, len = name.length; j < len; j++) {
            item = name[j];
            lastChild = this.element(item);
          }
        } else if (isFunction(name)) {
          lastChild = this.element(name.apply());
        } else if (isObject(name)) {
          for (key in name) {
            if (!hasProp.call(name, key)) continue;
            val = name[key];

            if (isFunction(val)) {
              val = val.apply();
            }

            if (isObject(val) && isEmpty(val)) {
              val = null;
            }

            if (!(this || _global).options.ignoreDecorators && (this || _global).stringify.convertAttKey && key.indexOf((this || _global).stringify.convertAttKey) === 0) {
              lastChild = this.attribute(key.substr((this || _global).stringify.convertAttKey.length), val);
            } else if (!(this || _global).options.separateArrayItems && Array.isArray(val)) {
              for (k = 0, len1 = val.length; k < len1; k++) {
                item = val[k];
                childNode = {};
                childNode[key] = item;
                lastChild = this.element(childNode);
              }
            } else if (isObject(val)) {
              lastChild = this.element(key);
              lastChild.element(val);
            } else {
              lastChild = this.element(key, val);
            }
          }
        } else if ((this || _global).options.skipNullNodes && text === null) {
          lastChild = this.dummy();
        } else {
          if (!(this || _global).options.ignoreDecorators && (this || _global).stringify.convertTextKey && name.indexOf((this || _global).stringify.convertTextKey) === 0) {
            lastChild = this.text(text);
          } else if (!(this || _global).options.ignoreDecorators && (this || _global).stringify.convertCDataKey && name.indexOf((this || _global).stringify.convertCDataKey) === 0) {
            lastChild = this.cdata(text);
          } else if (!(this || _global).options.ignoreDecorators && (this || _global).stringify.convertCommentKey && name.indexOf((this || _global).stringify.convertCommentKey) === 0) {
            lastChild = this.comment(text);
          } else if (!(this || _global).options.ignoreDecorators && (this || _global).stringify.convertRawKey && name.indexOf((this || _global).stringify.convertRawKey) === 0) {
            lastChild = this.raw(text);
          } else if (!(this || _global).options.ignoreDecorators && (this || _global).stringify.convertPIKey && name.indexOf((this || _global).stringify.convertPIKey) === 0) {
            lastChild = this.instruction(name.substr((this || _global).stringify.convertPIKey.length), text);
          } else {
            lastChild = this.node(name, attributes, text);
          }
        }

        if (lastChild == null) {
          throw new Error("Could not create any elements with: " + name + ". " + this.debugInfo());
        }

        return lastChild;
      };

      XMLNode.prototype.insertBefore = function (name, attributes, text) {
        var child, i, removed;

        if ((this || _global).isRoot) {
          throw new Error("Cannot insert elements at root level. " + this.debugInfo(name));
        }

        i = (this || _global).parent.children.indexOf(this || _global);
        removed = (this || _global).parent.children.splice(i);
        child = (this || _global).parent.element(name, attributes, text);
        Array.prototype.push.apply((this || _global).parent.children, removed);
        return child;
      };

      XMLNode.prototype.insertAfter = function (name, attributes, text) {
        var child, i, removed;

        if ((this || _global).isRoot) {
          throw new Error("Cannot insert elements at root level. " + this.debugInfo(name));
        }

        i = (this || _global).parent.children.indexOf(this || _global);
        removed = (this || _global).parent.children.splice(i + 1);
        child = (this || _global).parent.element(name, attributes, text);
        Array.prototype.push.apply((this || _global).parent.children, removed);
        return child;
      };

      XMLNode.prototype.remove = function () {
        var i, ref1;

        if ((this || _global).isRoot) {
          throw new Error("Cannot remove the root element. " + this.debugInfo());
        }

        i = (this || _global).parent.children.indexOf(this || _global);
        [].splice.apply((this || _global).parent.children, [i, i - i + 1].concat(ref1 = [])), ref1;
        return (this || _global).parent;
      };

      XMLNode.prototype.node = function (name, attributes, text) {
        var child, ref1;

        if (name != null) {
          name = getValue(name);
        }

        attributes || (attributes = {});
        attributes = getValue(attributes);

        if (!isObject(attributes)) {
          ref1 = [attributes, text], text = ref1[0], attributes = ref1[1];
        }

        child = new XMLElement(this || _global, name, attributes);

        if (text != null) {
          child.text(text);
        }

        (this || _global).children.push(child);

        return child;
      };

      XMLNode.prototype.text = function (value) {
        var child;
        child = new XMLText(this || _global, value);

        (this || _global).children.push(child);

        return this || _global;
      };

      XMLNode.prototype.cdata = function (value) {
        var child;
        child = new XMLCData(this || _global, value);

        (this || _global).children.push(child);

        return this || _global;
      };

      XMLNode.prototype.comment = function (value) {
        var child;
        child = new XMLComment(this || _global, value);

        (this || _global).children.push(child);

        return this || _global;
      };

      XMLNode.prototype.commentBefore = function (value) {
        var child, i, removed;
        i = (this || _global).parent.children.indexOf(this || _global);
        removed = (this || _global).parent.children.splice(i);
        child = (this || _global).parent.comment(value);
        Array.prototype.push.apply((this || _global).parent.children, removed);
        return this || _global;
      };

      XMLNode.prototype.commentAfter = function (value) {
        var child, i, removed;
        i = (this || _global).parent.children.indexOf(this || _global);
        removed = (this || _global).parent.children.splice(i + 1);
        child = (this || _global).parent.comment(value);
        Array.prototype.push.apply((this || _global).parent.children, removed);
        return this || _global;
      };

      XMLNode.prototype.raw = function (value) {
        var child;
        child = new XMLRaw(this || _global, value);

        (this || _global).children.push(child);

        return this || _global;
      };

      XMLNode.prototype.dummy = function () {
        var child;
        child = new XMLDummy(this || _global);

        (this || _global).children.push(child);

        return child;
      };

      XMLNode.prototype.instruction = function (target, value) {
        var insTarget, insValue, instruction, j, len;

        if (target != null) {
          target = getValue(target);
        }

        if (value != null) {
          value = getValue(value);
        }

        if (Array.isArray(target)) {
          for (j = 0, len = target.length; j < len; j++) {
            insTarget = target[j];
            this.instruction(insTarget);
          }
        } else if (isObject(target)) {
          for (insTarget in target) {
            if (!hasProp.call(target, insTarget)) continue;
            insValue = target[insTarget];
            this.instruction(insTarget, insValue);
          }
        } else {
          if (isFunction(value)) {
            value = value.apply();
          }

          instruction = new XMLProcessingInstruction(this || _global, target, value);

          (this || _global).children.push(instruction);
        }

        return this || _global;
      };

      XMLNode.prototype.instructionBefore = function (target, value) {
        var child, i, removed;
        i = (this || _global).parent.children.indexOf(this || _global);
        removed = (this || _global).parent.children.splice(i);
        child = (this || _global).parent.instruction(target, value);
        Array.prototype.push.apply((this || _global).parent.children, removed);
        return this || _global;
      };

      XMLNode.prototype.instructionAfter = function (target, value) {
        var child, i, removed;
        i = (this || _global).parent.children.indexOf(this || _global);
        removed = (this || _global).parent.children.splice(i + 1);
        child = (this || _global).parent.instruction(target, value);
        Array.prototype.push.apply((this || _global).parent.children, removed);
        return this || _global;
      };

      XMLNode.prototype.declaration = function (version, encoding, standalone) {
        var doc, xmldec;
        doc = this.document();
        xmldec = new XMLDeclaration(doc, version, encoding, standalone);

        if (doc.children[0] instanceof XMLDeclaration) {
          doc.children[0] = xmldec;
        } else {
          doc.children.unshift(xmldec);
        }

        return doc.root() || doc;
      };

      XMLNode.prototype.doctype = function (pubID, sysID) {
        var child, doc, doctype, i, j, k, len, len1, ref1, ref2;
        doc = this.document();
        doctype = new XMLDocType(doc, pubID, sysID);
        ref1 = doc.children;

        for (i = j = 0, len = ref1.length; j < len; i = ++j) {
          child = ref1[i];

          if (child instanceof XMLDocType) {
            doc.children[i] = doctype;
            return doctype;
          }
        }

        ref2 = doc.children;

        for (i = k = 0, len1 = ref2.length; k < len1; i = ++k) {
          child = ref2[i];

          if (child.isRoot) {
            doc.children.splice(i, 0, doctype);
            return doctype;
          }
        }

        doc.children.push(doctype);
        return doctype;
      };

      XMLNode.prototype.up = function () {
        if ((this || _global).isRoot) {
          throw new Error("The root node has no parent. Use doc() if you need to get the document object.");
        }

        return (this || _global).parent;
      };

      XMLNode.prototype.root = function () {
        var node;
        node = this || _global;

        while (node) {
          if (node.isDocument) {
            return node.rootObject;
          } else if (node.isRoot) {
            return node;
          } else {
            node = node.parent;
          }
        }
      };

      XMLNode.prototype.document = function () {
        var node;
        node = this || _global;

        while (node) {
          if (node.isDocument) {
            return node;
          } else {
            node = node.parent;
          }
        }
      };

      XMLNode.prototype.end = function (options) {
        return this.document().end(options);
      };

      XMLNode.prototype.prev = function () {
        var i;
        i = (this || _global).parent.children.indexOf(this || _global);

        while (i > 0 && (this || _global).parent.children[i - 1].isDummy) {
          i = i - 1;
        }

        if (i < 1) {
          throw new Error("Already at the first node. " + this.debugInfo());
        }

        return (this || _global).parent.children[i - 1];
      };

      XMLNode.prototype.next = function () {
        var i;
        i = (this || _global).parent.children.indexOf(this || _global);

        while (i < (this || _global).parent.children.length - 1 && (this || _global).parent.children[i + 1].isDummy) {
          i = i + 1;
        }

        if (i === -1 || i === (this || _global).parent.children.length - 1) {
          throw new Error("Already at the last node. " + this.debugInfo());
        }

        return (this || _global).parent.children[i + 1];
      };

      XMLNode.prototype.importDocument = function (doc) {
        var clonedRoot;
        clonedRoot = doc.root().clone();
        clonedRoot.parent = this || _global;
        clonedRoot.isRoot = false;

        (this || _global).children.push(clonedRoot);

        return this || _global;
      };

      XMLNode.prototype.debugInfo = function (name) {
        var ref1, ref2;
        name = name || (this || _global).name;

        if (name == null && !((ref1 = (this || _global).parent) != null ? ref1.name : void 0)) {
          return "";
        } else if (name == null) {
          return "parent: <" + (this || _global).parent.name + ">";
        } else if (!((ref2 = (this || _global).parent) != null ? ref2.name : void 0)) {
          return "node: <" + name + ">";
        } else {
          return "node: <" + name + ">, parent: <" + (this || _global).parent.name + ">";
        }
      };

      XMLNode.prototype.ele = function (name, attributes, text) {
        return this.element(name, attributes, text);
      };

      XMLNode.prototype.nod = function (name, attributes, text) {
        return this.node(name, attributes, text);
      };

      XMLNode.prototype.txt = function (value) {
        return this.text(value);
      };

      XMLNode.prototype.dat = function (value) {
        return this.cdata(value);
      };

      XMLNode.prototype.com = function (value) {
        return this.comment(value);
      };

      XMLNode.prototype.ins = function (target, value) {
        return this.instruction(target, value);
      };

      XMLNode.prototype.doc = function () {
        return this.document();
      };

      XMLNode.prototype.dec = function (version, encoding, standalone) {
        return this.declaration(version, encoding, standalone);
      };

      XMLNode.prototype.dtd = function (pubID, sysID) {
        return this.doctype(pubID, sysID);
      };

      XMLNode.prototype.e = function (name, attributes, text) {
        return this.element(name, attributes, text);
      };

      XMLNode.prototype.n = function (name, attributes, text) {
        return this.node(name, attributes, text);
      };

      XMLNode.prototype.t = function (value) {
        return this.text(value);
      };

      XMLNode.prototype.d = function (value) {
        return this.cdata(value);
      };

      XMLNode.prototype.c = function (value) {
        return this.comment(value);
      };

      XMLNode.prototype.r = function (value) {
        return this.raw(value);
      };

      XMLNode.prototype.i = function (target, value) {
        return this.instruction(target, value);
      };

      XMLNode.prototype.u = function () {
        return this.up();
      };

      XMLNode.prototype.importXMLBuilder = function (doc) {
        return this.importDocument(doc);
      };

      return XMLNode;
    }();
  }).call(exports);
  return exports;
}