import { dew as _XMLNodeDew } from "./XMLNode";
var exports = {},
    _dewExec = false;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  // Generated by CoffeeScript 1.12.7
  (function () {
    var XMLDTDAttList,
        XMLNode,
        extend = function (child, parent) {
      for (var key in parent) {
        if (hasProp.call(parent, key)) child[key] = parent[key];
      }

      function ctor() {
        (this || _global).constructor = child;
      }

      ctor.prototype = parent.prototype;
      child.prototype = new ctor();
      child.__super__ = parent.prototype;
      return child;
    },
        hasProp = {}.hasOwnProperty;

    XMLNode = _XMLNodeDew();

    exports = XMLDTDAttList = function (superClass) {
      extend(XMLDTDAttList, superClass);

      function XMLDTDAttList(parent, elementName, attributeName, attributeType, defaultValueType, defaultValue) {
        XMLDTDAttList.__super__.constructor.call(this || _global, parent);

        if (elementName == null) {
          throw new Error("Missing DTD element name. " + this.debugInfo());
        }

        if (attributeName == null) {
          throw new Error("Missing DTD attribute name. " + this.debugInfo(elementName));
        }

        if (!attributeType) {
          throw new Error("Missing DTD attribute type. " + this.debugInfo(elementName));
        }

        if (!defaultValueType) {
          throw new Error("Missing DTD attribute default. " + this.debugInfo(elementName));
        }

        if (defaultValueType.indexOf("#") !== 0) {
          defaultValueType = "#" + defaultValueType;
        }

        if (!defaultValueType.match(/^(#REQUIRED|#IMPLIED|#FIXED|#DEFAULT)$/)) {
          throw new Error("Invalid default value type; expected: #REQUIRED, #IMPLIED, #FIXED or #DEFAULT. " + this.debugInfo(elementName));
        }

        if (defaultValue && !defaultValueType.match(/^(#FIXED|#DEFAULT)$/)) {
          throw new Error("Default value only applies to #FIXED or #DEFAULT. " + this.debugInfo(elementName));
        }

        (this || _global).elementName = (this || _global).stringify.eleName(elementName);
        (this || _global).attributeName = (this || _global).stringify.attName(attributeName);
        (this || _global).attributeType = (this || _global).stringify.dtdAttType(attributeType);
        (this || _global).defaultValue = (this || _global).stringify.dtdAttDefault(defaultValue);
        (this || _global).defaultValueType = defaultValueType;
      }

      XMLDTDAttList.prototype.toString = function (options) {
        return (this || _global).options.writer.set(options).dtdAttList(this || _global);
      };

      return XMLDTDAttList;
    }(XMLNode);
  }).call(exports);
  return exports;
}