import { dew as _UtilityDew } from "./Utility";
import { dew as _XMLNodeDew } from "./XMLNode";
import { dew as _XMLAttributeDew } from "./XMLAttribute";
var exports = {},
    _dewExec = false;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  // Generated by CoffeeScript 1.12.7
  (function () {
    var XMLAttribute,
        XMLElement,
        XMLNode,
        getValue,
        isFunction,
        isObject,
        ref,
        extend = function (child, parent) {
      for (var key in parent) {
        if (hasProp.call(parent, key)) child[key] = parent[key];
      }

      function ctor() {
        (this || _global).constructor = child;
      }

      ctor.prototype = parent.prototype;
      child.prototype = new ctor();
      child.__super__ = parent.prototype;
      return child;
    },
        hasProp = {}.hasOwnProperty;

    ref = _UtilityDew(), isObject = ref.isObject, isFunction = ref.isFunction, getValue = ref.getValue;
    XMLNode = _XMLNodeDew();
    XMLAttribute = _XMLAttributeDew();

    exports = XMLElement = function (superClass) {
      extend(XMLElement, superClass);

      function XMLElement(parent, name, attributes) {
        XMLElement.__super__.constructor.call(this || _global, parent);

        if (name == null) {
          throw new Error("Missing element name. " + this.debugInfo());
        }

        (this || _global).name = (this || _global).stringify.eleName(name);
        (this || _global).attributes = {};

        if (attributes != null) {
          this.attribute(attributes);
        }

        if (parent.isDocument) {
          (this || _global).isRoot = true;
          (this || _global).documentObject = parent;
          parent.rootObject = this || _global;
        }
      }

      XMLElement.prototype.clone = function () {
        var att, attName, clonedSelf, ref1;
        clonedSelf = Object.create(this || _global);

        if (clonedSelf.isRoot) {
          clonedSelf.documentObject = null;
        }

        clonedSelf.attributes = {};
        ref1 = (this || _global).attributes;

        for (attName in ref1) {
          if (!hasProp.call(ref1, attName)) continue;
          att = ref1[attName];
          clonedSelf.attributes[attName] = att.clone();
        }

        clonedSelf.children = [];

        (this || _global).children.forEach(function (child) {
          var clonedChild;
          clonedChild = child.clone();
          clonedChild.parent = clonedSelf;
          return clonedSelf.children.push(clonedChild);
        });

        return clonedSelf;
      };

      XMLElement.prototype.attribute = function (name, value) {
        var attName, attValue;

        if (name != null) {
          name = getValue(name);
        }

        if (isObject(name)) {
          for (attName in name) {
            if (!hasProp.call(name, attName)) continue;
            attValue = name[attName];
            this.attribute(attName, attValue);
          }
        } else {
          if (isFunction(value)) {
            value = value.apply();
          }

          if (!(this || _global).options.skipNullAttributes || value != null) {
            (this || _global).attributes[name] = new XMLAttribute(this || _global, name, value);
          }
        }

        return this || _global;
      };

      XMLElement.prototype.removeAttribute = function (name) {
        var attName, i, len;

        if (name == null) {
          throw new Error("Missing attribute name. " + this.debugInfo());
        }

        name = getValue(name);

        if (Array.isArray(name)) {
          for (i = 0, len = name.length; i < len; i++) {
            attName = name[i];
            delete (this || _global).attributes[attName];
          }
        } else {
          delete (this || _global).attributes[name];
        }

        return this || _global;
      };

      XMLElement.prototype.toString = function (options) {
        return (this || _global).options.writer.set(options).element(this || _global);
      };

      XMLElement.prototype.att = function (name, value) {
        return this.attribute(name, value);
      };

      XMLElement.prototype.a = function (name, value) {
        return this.attribute(name, value);
      };

      return XMLElement;
    }(XMLNode);
  }).call(exports);
  return exports;
}